import { useCallback, useEffect, useMemo } from "react"

import HighchartsReact from "highcharts-react-official"

import { TClockTypeMaybe } from "src/data/user/user"
import { mColors } from "src/ui/colors"

import { TLineChartData } from "./graphTypes"
import { LoadingTooltip } from "./LoadingTooltip"

// How far away the latest data point has to be from NOW before we show the loading point
const CLOSEST_POINT_BUFFER = 30 * 60 * 1000 // 30 minutes

export type LoadingPointConfig =
  | {
      enabled: true
      nextUpdate: Date // When the device will update next
      lastUpdate: Date // The last time the device updated
      endDate: Date // The end date of the graph query
    }
  | { enabled: false }

function showLoadingPoint(
  loadingPointConfig: LoadingPointConfig,
  data: TLineChartData[]
): { show: true; nextUpdate: number } | { show: false; nextUpdate?: never } {
  const now = Date.now()

  // If the loading point is not enabled, don't show it
  if (!loadingPointConfig.enabled) {
    return { show: false }
  }

  // If the searched end date is before the last update, we don't need to show the loading point
  // as the user is looking at historical data
  if (
    loadingPointConfig.endDate.getTime() <
    loadingPointConfig.lastUpdate.getTime()
  ) {
    return { show: false }
  }

  const latestDataPoint = data[data.length - 1]
  const latestDataPointTime = new Date(
    latestDataPoint?.dateTimeUtc ?? ""
  ).getTime()

  // If the latest data point is less than CLOSEST_POINT_BUFFER away from the current time, don't show loading point
  // Ex: User searched up to "20:00", current time is "18:00" but the last data point was "17:45" -> Don't show the loading point (as we had a recent data point, so it will look cramped on the graph)
  // Ex: User searched up to "20:00", current time is "19:00" but the last data point was "17:45" -> Show the loading point (as the last data point is more than CLOSEST_POINT_BUFFER away)
  if (now - latestDataPointTime < CLOSEST_POINT_BUFFER) {
    return { show: false }
  }

  return { show: true, nextUpdate: loadingPointConfig.nextUpdate.getTime() }
}

export const useLoadingPoint = ({
  loadingPointConfig,
  data,
  chartRef,
  clockType,
  timezone,
}: {
  loadingPointConfig: LoadingPointConfig
  data: TLineChartData[]
  chartRef: React.RefObject<HighchartsReact.RefObject>
  clockType: TClockTypeMaybe
  timezone: string
}) => {
  const { show, nextUpdate } = useMemo(
    () => showLoadingPoint(loadingPointConfig, data),
    [loadingPointConfig, data]
  )

  const loadingPoint = useMemo<[number, number] | undefined>(() => {
    if (!show) {
      return
    }

    const latestDataPoint = data[data.length - 1]

    // Calculate the average of the last 20 data points
    // Just something we can use to make the loading point look more natural
    const average = data.slice(-20).reduce((acc, point) => {
      const value = point.value ?? 0
      return (acc + value) / 2
    }, data[0]?.value ?? 0)

    const latestDataPointValue = latestDataPoint?.value ?? 0

    return [Date.now(), latestDataPointValue + (latestDataPointValue - average)]
  }, [data, show])

  const getLoadingPointTooltip = useCallback(
    (xValue: number | string) => {
      if (!show) {
        return undefined
      }
      if (!loadingPoint) {
        return undefined
      }

      if (loadingPoint[0] !== xValue) {
        return undefined
      }

      return (
        <LoadingTooltip
          nextUpdateAt={nextUpdate}
          clockType={clockType}
          timezone={timezone}
        />
      )
    },
    [loadingPoint, show, nextUpdate, clockType, timezone]
  )

  useEffect(() => {
    if (!loadingPointConfig.enabled) {
      return
    }

    if (!loadingPoint) {
      return
    }

    const chart = chartRef.current?.chart
    const lowerBound = 4
    const upperBound = 12
    const delay = 50

    const animationState: {
      value: number
      direction: "up" | "down"
    } = {
      value: lowerBound,
      direction: "up",
    }

    let annotation: Highcharts.Annotation | undefined = undefined

    let animationId: number | undefined = undefined
    let timeoutId: NodeJS.Timeout | undefined = undefined

    function animate() {
      if (!chart) {
        return
      }

      if (annotation) {
        chart.removeAnnotation(annotation)
      }

      annotation = chart.addAnnotation(createAnnotation(animationState.value))

      if (animationState.direction === "up") {
        animationState.value += 1
      } else {
        animationState.value -= 1
      }
      if (animationState.value > upperBound) {
        animationState.direction = "down"
      } else if (animationState.value < lowerBound) {
        animationState.direction = "up"
      }

      timeoutId = setTimeout(() => {
        animationId = requestAnimationFrame(animate)
      }, delay)
    }

    animationId = requestAnimationFrame(animate)

    return () => {
      if (animationId) {
        cancelAnimationFrame(animationId)
      }
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
      if (annotation) {
        try {
          chart?.removeAnnotation(annotation)
        } catch (e) {
          // Highcharts throws an error when removing an annotation that doesn't exist
          // Can safely ignore, as it's only for cleanup
        }
      }
    }
  }, [data, chartRef, loadingPointConfig, loadingPoint])

  return { loadingPoint, getLoadingPointTooltip }
}

function createAnnotation(radius: number): Highcharts.AnnotationsOptions {
  return {
    draggable: "",
    crop: false,
    id: "loading-dot",
    shapes: [
      {
        type: "circle",
        r: 4,
        fill: mColors.textTertiary,
        strokeWidth: 0,
        point: (annotation) => {
          const lineSeries = annotation.chart.series[0]
          const lastPoint =
            lineSeries?.points[(lineSeries.points.length ?? 1) - 1]
          const x = lastPoint?.x ?? 0
          const y = lastPoint?.y ?? 0

          return {
            x: x,
            y: y,
            xAxis: 0,
            yAxis: 0,
          }
        },
      },
      {
        type: "circle",
        r: radius,
        fill: mColors.textTertiary,
        strokeWidth: 0,
        point: (annotation) => {
          const lineSeries = annotation.chart.series[0]
          const lastPoint =
            lineSeries?.points[(lineSeries.points.length ?? 1) - 1]
          const x = lastPoint?.x ?? 0
          const y = lastPoint?.y ?? 0

          return {
            x: x,
            y: y,
            xAxis: 0,
            yAxis: 0,
          }
        },
      },
    ],
  }
}
