import { useMemo } from "react"

import { XAxisPlotLinesOptions } from "highcharts"

import { mColors } from "src/ui/colors"

export type AnnotatedEvent = {
  value: number
  text: string
}

export function useAnnotatedPlotLines({
  annotatedEvents,
}: {
  annotatedEvents: AnnotatedEvent[]
}) {
  const plotLines: XAxisPlotLinesOptions[] = useMemo(() => {
    return annotatedEvents.map((event) => ({
      value: event.value,
      color: mColors.systemGood,
      width: 1,
      dashStyle: "ShortDash",
    }))
  }, [annotatedEvents])

  const annotations = useMemo<Highcharts.AnnotationsOptions[]>(() => {
    return annotatedEvents.map((event) => {
      return {
        animation: false,
        draggable: "",
        labels: [
          {
            text: event.text,
            align: "center",
            verticalAlign: "top",
            backgroundColor: mColors.primaryLight,
            allowOverlap: true,
            distance: 10,
            point: {
              x: event.value ?? 0,
              y: 0,
              xAxis: 0,
              yAxis: null,
            },
            style: {
              color: mColors.textPrimary,
            },
          },
        ],
      } satisfies Highcharts.AnnotationsOptions
    })
  }, [annotatedEvents])

  return { annotations, plotLines }
}
