import styled from "styled-components"

import { TClockTypeMaybe } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { mColors } from "src/ui/colors"
import { localizedDate } from "src/ui/LocalizedDate/localized-date"
import { MCircularProgress } from "src/ui/MCircularProgress/MCircularProgress"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { extractHHMM } from "src/utils/l10n"

import { GenericTooltip } from "./GenericTooltip"

export function LoadingTooltip({
  nextUpdateAt,
  clockType,
  timezone,
}: {
  nextUpdateAt: number
  clockType: TClockTypeMaybe
  timezone: string
}) {
  const formattedTime = extractHHMM(
    new Date(
      localizedDate(new Date(nextUpdateAt).toISOString(), clockType, timezone)
    ),
    clockType
  )

  const { t, langKeys } = useTranslate()

  return (
    <GenericTooltip
      header={
        <MText color="primary" variant="bodyS">
          {t(langKeys.device_next_update) + " " + formattedTime}
        </MText>
      }
    >
      <LoadingRow key="loading">
        <MCircularProgress size={20} color={mColors.primary} />
        <MText color="primary" variant="subtitle">
          {t(langKeys.device_graph_collecting_data)}
        </MText>
      </LoadingRow>
    </GenericTooltip>
  )
}

const LoadingRow = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.S};
`
