import { useCallback, useMemo, useRef } from "react"
import ReactServer from "react-dom/server"
import styled from "styled-components"

import * as Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsAnnotations from "highcharts/modules/annotations"
import HighchartsBoost from "highcharts/modules/boost"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExport from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { TClockType, TUser } from "src/data/user/user"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { colorScale, mColors } from "src/ui/colors"
import { ChartTooltip } from "src/ui/Graphs/ChartTooltip"
import { ITimeScopedThreshold } from "src/ui/Graphs/configurationUtils"
import {
  getAxisOptions,
  getThresholdDataSeries,
  getYPlotLinesDataSeries,
} from "src/ui/Graphs/dataUtils"
import { TLineChartData } from "src/ui/Graphs/graphTypes"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatDate } from "src/utils/l10n"

import { AnnotatedEvent, useAnnotatedPlotLines } from "./useAnnotatedPlotLines"
import { LoadingPointConfig, useLoadingPoint } from "./useLoadingPoint"

HighchartsExport(Highcharts)
HighchartsExportData(Highcharts)
HighchartsMore(Highcharts)
HighchartsBoost(Highcharts)
HighchartsAnnotations(Highcharts)
Highcharts.AST.allowedAttributes.push(
  "viewBox",
  "stroke-linecap",
  "stroke-dasharray"
)

export type LineChartProps = {
  data: TLineChartData[]
  tooltip?: {
    unit?: string
    decimals?: number
    formatter?: (context: {
      date: Date
      value: number
      min?: number
      max?: number
    }) => React.ReactElement
  }
  /**
   * Enable/disable interactivity within the chart
   *
   * If set to `false` all tooltips, crosshairs and zoom will be disabled
   */
  interactive?: boolean
  canExport?: boolean
  thresholds?: ITimeScopedThreshold[]
  annotatedEvents?: AnnotatedEvent[]
  yPlotLines?: number[]
  onZoom?: (startDate: Date, endDate: Date) => void
  onZoomReset?: () => void
  smooth?: boolean
  zoom?: "x" | "xy" | "y"
  timezone: string
  clockType: TClockType
  yAxisOptions?: Highcharts.YAxisOptions
  xAxisOptions?: Highcharts.XAxisOptions
  lineColor?: string
  /**
   * Render a marker on the last point of data in the chart with a label that is the value of the point's y-value
   */
  lineEndMarker?: {
    unit: string
    decimals?: number
  }
  loadingPointConfig?: LoadingPointConfig
  height?: number
  step?: Highcharts.SeriesLineOptions["step"]
  dateRange?: IGraphDateRange
}

export function LineChart({
  data,
  thresholds,
  yPlotLines,
  tooltip,
  interactive = true,
  onZoom,
  onZoomReset,
  smooth = true,
  canExport = false,
  zoom = "x",
  timezone,
  clockType,
  yAxisOptions,
  xAxisOptions,
  lineColor = mColors.primary,
  lineEndMarker,
  loadingPointConfig = { enabled: false },
  height,
  step,
  annotatedEvents = [],
  dateRange,
}: LineChartProps) {
  const { t } = useTranslate()
  const minMaxIncluded = !!data[0]?.max && !!data[0]?.min

  const ref = useRef<HighchartsReact.RefObject>(null)

  const { annotations, plotLines: annotatedPlotLines } = useAnnotatedPlotLines({
    annotatedEvents,
  })

  const { loadingPoint, getLoadingPointTooltip } = useLoadingPoint({
    loadingPointConfig,
    data,
    chartRef: ref,
    clockType,
    timezone,
  })

  const series = useMemo<Highcharts.Options["series"]>(() => {
    const lineWidth = 2

    const dataSeries: Highcharts.Options["series"] = []
    const latestDataPoint = data[data.length - 1]
    const latestDataPointTime = new Date(
      latestDataPoint?.dateTimeUtc ?? dateRange?.endDate.getTime() ?? ""
    ).getTime()

    const zones: Highcharts.SeriesZonesOptionsObject[] = [
      {
        value: latestDataPointTime,
        color: lineColor,
        dashStyle: "Solid",
      },
    ]

    if (loadingPoint) {
      zones.push({
        value: loadingPoint[0],
        color: colorScale.koti[400],
        dashStyle: "ShortDash",
      })
    }

    dataSeries.push({
      name: "Value",
      type: smooth ? "spline" : "line",
      color: lineColor,
      showInLegend: false,
      animation: true,
      connectNulls: true,
      step,
      marker: {
        enabled: false,
        fillColor: mColors.primary,
        lineWidth: 1,
        lineColor: "white",
        radius: 2,
      },
      zones,
      zoneAxis: "x",
      lineWidth,
      dataLabels: {
        enabled: !!lineEndMarker,
        filter: {
          operator: ">=",
          property: "x",
          value: new Date(data[data.length - 1]?.dateTimeUtc ?? "").getTime(),
        },

        useHTML: true,
        formatter: function (this, _) {
          return ReactServer.renderToString(
            <MText>
              {this.y?.toFixed(lineEndMarker?.decimals)} {lineEndMarker?.unit}
            </MText>
          )
        },
      },
      states: {
        hover: { lineWidth, enabled: interactive },
        inactive: { enabled: false },
      },
      data: data
        .map((dataPoint) => [
          new Date(dataPoint.dateTimeUtc).getTime(),
          dataPoint.value,
        ])
        .concat(loadingPoint ? [loadingPoint] : []),
    })

    if (thresholds) {
      const thresholdDataSeries = getThresholdDataSeries(
        thresholds,
        dateRange,
        timezone,
        data
      )

      dataSeries.push(thresholdDataSeries)
    }

    if (yPlotLines) {
      const yPlotLinesDataSeries = getYPlotLinesDataSeries(
        yPlotLines,
        dateRange,
        data
      )

      dataSeries.push(...yPlotLinesDataSeries)
    }

    if (minMaxIncluded) {
      dataSeries.push({
        name: "Area",
        type: smooth ? "areasplinerange" : "arearange",
        linkedTo: ":previous",
        lineColor: "transparent",
        fillColor: mColors.primary,
        opacity: 0.1,
        connectNulls: false,
        marker: { enabled: false },
        states: { hover: { enabled: false } },
        data: data.map((dataPoint) => [
          new Date(dataPoint.dateTimeUtc).getTime(),
          dataPoint.min,
          dataPoint.max,
        ]),
      } as Highcharts.SeriesAreasplinerangeOptions)
    }

    return dataSeries
  }, [
    data,
    lineColor,
    loadingPoint,
    smooth,
    step,
    lineEndMarker,
    interactive,
    thresholds,
    yPlotLines,
    minMaxIncluded,
    dateRange,
    timezone,
  ])

  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (context.x == null || context.y == null) return false

      const loadingPointTooltip = getLoadingPointTooltip(context.x)

      if (loadingPointTooltip) {
        return ReactServer.renderToString(loadingPointTooltip)
      }

      if (tooltip?.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
            min: context.points?.[1]?.point?.low,
            max: context.points?.[1]?.point?.high,
          })
        )
      }

      const date = formatDate({
        date: new Date(context.x).toISOString(),
        clockType,
        timezone,
      })
      const decimals = tooltip?.decimals ?? 1
      const high = context.points?.[1]?.point?.high?.toFixed(decimals)
      const low = context.points?.[1]?.point?.low?.toFixed(decimals)

      const unit = tooltip?.unit || ""

      const contentLabel = minMaxIncluded
        ? t(langKeys.average)
        : t(langKeys.value)

      return ReactServer.renderToString(
        <ChartTooltip
          date={date}
          label={contentLabel}
          value={context.y.toFixed(decimals)}
          unit={unit}
          minMaxIncluded={minMaxIncluded}
          low={low}
          high={high}
        />
      )
    },
    [clockType, minMaxIncluded, t, timezone, tooltip, getLoadingPointTooltip]
  )

  const axisOptions = useMemo(
    () => getAxisOptions(data, dateRange, yPlotLines, thresholds),
    [data, dateRange, yPlotLines, thresholds]
  )

  const options = useMemo<Highcharts.Options>(
    () => ({
      accessibility: { enabled: false }, // Make Highcharts stfu about a11y
      time: {
        timezone,
      },
      chart: {
        height,
        zooming: interactive
          ? {
              type: zoom,
              pinchType: zoom,
              // TODO WEB-XXX: Configure button theme here
              // https://api.highcharts.com/highcharts/chart.zooming.resetButton
              // resetButton: {theme: {}}
            }
          : {},
        events: {
          selection(e) {
            if (onZoom) {
              if (e.resetSelection) {
                onZoomReset?.()
                this.xAxis[0]?.setExtremes(
                  this.xAxis[0]?.getExtremes().dataMin,
                  this.xAxis[0]?.getExtremes().dataMax,
                  false
                )
              } else {
                const zMin = e.xAxis[0]?.min
                const zMax = e.xAxis[0]?.max
                zMin && zMax && onZoom(new Date(zMin), new Date(zMax))
                this.showResetZoom()
                return false
              }
            }

            return true
          },
        },
      },
      title: {
        text: "",
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },

      credits: { enabled: false },
      xAxis: {
        type: "datetime",
        plotLines: annotatedPlotLines,
        lineWidth: 1,
        lineColor: mColors.divider,
        tickColor: mColors.divider,
        crosshair: interactive
          ? {
              color: mColors.primary,
              dashStyle: "ShortDot",
              width: 2,
            }
          : false,
        dateTimeLabelFormats: getXAxisDateTimeLabelFormat(clockType),
        labels: {
          style: {
            fontFamily: "'Figtree', sans-serif",
            fontSize: "12px",
            color: mColors.textTertiary,
          },
        },
        tickAmount: 8,
        ...axisOptions.xAxisOptions,
        ...xAxisOptions,
      },
      yAxis: {
        type: "linear",
        title: {
          text: "",
        },

        endOnTick: true,
        startOnTick: true,
        crosshair: false,
        labels: {
          style: {
            fontFamily: "'Figtree', sans-serif",
            fontSize: "12px",
            color: mColors.textTertiary,
          },
        },
        // Use 10% padding on both sides of the axis:
        minPadding: 0.1,
        maxPadding: 0.1,
        gridLineColor: mColors.divider,
        ...axisOptions.yAxisOptions,
        ...yAxisOptions,
      },
      series,
      tooltip: {
        enabled: interactive,
        useHTML: true,
        padding: 0,
        shared: true,
        shadow: false,
        positioner(labelWidth, labelHeight, point) {
          let x = point.plotX + this.chart.plotLeft + 10
          let y = point.plotY + this.chart.plotTop + 10

          if (x + labelWidth >= this.chart.plotWidth) {
            x = point.plotX - labelWidth + this.chart.plotLeft - 10
          }

          if (y + labelHeight >= this.chart.plotHeight) {
            y = point.plotY - labelHeight + this.chart.plotTop - 10
          }

          return {
            x: x,
            y: y,
          }
        },
        formatter() {
          return tooltipFormatter(this)
        },
      },
      annotations: !!lineEndMarker
        ? [createLineMarkerAnnotation(lineColor), ...annotations]
        : annotations,
    }),
    [
      timezone,
      height,
      interactive,
      zoom,
      canExport,
      annotatedPlotLines,
      clockType,
      axisOptions.xAxisOptions,
      axisOptions.yAxisOptions,
      xAxisOptions,
      yAxisOptions,
      series,
      lineEndMarker,
      lineColor,
      annotations,
      onZoom,
      onZoomReset,
      tooltipFormatter,
    ]
  )

  return (
    <Container>
      <HighchartsReact ref={ref} highcharts={Highcharts} options={options} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  gap: ${spacing.XL2};
  width: 100%;

  /**
    Due to not being able to set a class or inline style in the annotation shape 
    we have to do this
  */
  .highcharts-annotation-shapes > :first-child {
    opacity: 0.2;
  }
`

/** https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats */
const defaultDateTimeLabelFormat = {
  millisecond: "%H:%M:%S.%L",
  second: "%H:%M:%S",
  minute: "%H:%M",
  hour: "%H:%M",
  day: "%e %b",
  week: "%e %b",
  month: "%b '%y",
  year: "%Y",
} as const

function getXAxisDateTimeLabelFormat(
  clockType: TUser["clock_type"]
): Highcharts.AxisDateTimeLabelFormatsOptions {
  const use12hClock = clockType === "12"
  if (use12hClock) {
    return {
      ...defaultDateTimeLabelFormat,
      hour: `%I:%M %p`,
      day: "%a %e %b",
    }
  }
  return defaultDateTimeLabelFormat
}

function createLineMarkerAnnotation(
  lineColor: string
): Highcharts.AnnotationsOptions {
  return {
    draggable: "",
    crop: false,
    shapes: [
      // IMPORTANT
      // Order matter, the smaller circle has to come before the background (larger) circle to make sure it renders on top
      {
        type: "circle",
        r: 4,
        fill: lineColor,
        strokeWidth: 0,
        point: (annotation) => {
          const lineSeries = annotation.chart.series[0]
          const lastPoint =
            lineSeries?.points[(lineSeries.points.length ?? 1) - 1]
          const x = lastPoint?.x ?? 0
          const y = lastPoint?.y ?? 0

          return {
            x: x,
            y: y,
            xAxis: 0,
            yAxis: 0,
          }
        },
      },
      {
        type: "circle",
        r: 8,
        fill: lineColor,
        strokeWidth: 0,
        point: (annotation) => {
          const lineSeries = annotation.chart.series[0]
          const lastPoint =
            lineSeries?.points[(lineSeries.points.length ?? 1) - 1]
          const x = lastPoint?.x ?? 0
          const y = lastPoint?.y ?? 0

          return {
            x: x,
            y: y,
            xAxis: 0,
            yAxis: 0,
          }
        },
      },
    ],
  }
}
