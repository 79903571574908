import { mColors } from "@minuthq/meatball-ui-react"
import { eachHourOfInterval } from "date-fns"

import { IGraphDateRange } from "src/components/Homes/DeviceDetails/Overview/DeviceGraphs"
import { TGraphData } from "src/components/Homes/DeviceDetails/Overview/useGetGraphData"
import {
  getActiveNoiseThreshold,
  ITimeScopedThreshold,
} from "src/ui/Graphs/configurationUtils"
import { TLineChartData } from "src/ui/Graphs/graphTypes"

export function getAxisOptions(
  data?: TGraphData,
  dateRange?: IGraphDateRange,
  yPlotLines?: number[],
  thresholds?: ITimeScopedThreshold[]
): {
  xAxisOptions: Highcharts.XAxisOptions
  yAxisOptions: Highcharts.YAxisOptions
} {
  if ((data && data.length > 0) || !dateRange) {
    return {
      xAxisOptions: {},
      yAxisOptions: {},
    }
  }

  let yAxisOptions = {}

  if (!!yPlotLines) {
    yAxisOptions = {
      min: (yPlotLines[0] ?? 0) - 20,
      max: (yPlotLines[yPlotLines.length - 1] ?? 100) + 20,
    }
  }

  if (!!thresholds && thresholds.length > 0) {
    const sortedThresholds = thresholds.map((t) => t.value).sort()
    yAxisOptions = {
      min: (sortedThresholds[0] ?? 0) - 20,
      max: (sortedThresholds[thresholds.length - 1] ?? 100) + 20,
    }
  }

  return {
    xAxisOptions: {
      min: dateRange.startDate.getTime(),
      max: dateRange.endDate.getTime(),
      tickInterval: 3600 * 1000,
    },
    yAxisOptions,
  }
}

export function getThresholdDataSeries(
  thresholds: ITimeScopedThreshold[],
  dateRange: IGraphDateRange | undefined,
  timezone: string,
  data: TLineChartData[]
): Highcharts.SeriesOptionsType {
  function createData(date: Date) {
    const xVal = date

    const quietTimeThreshold = thresholds.find((t) => t.endHHMM && t.startHHMM)
    const dayTimeThreshold = thresholds.find((t) => !t.endHHMM)

    const tMinVal = getActiveNoiseThreshold({
      xVal,
      dayTimeThreshold,
      quietTimeThreshold,
      timezone,
    })

    // Highcharts convert null points to a point in time (1970).
    // By providing the x-value value but not the y-value this will not happen
    if (!tMinVal) {
      return [date.getTime(), null]
    }

    return [date.getTime(), tMinVal]
  }

  const thresholdData = data.map((dataPoint) =>
    createData(new Date(dataPoint.dateTimeUtc))
  )

  if (dateRange && data.length < 1) {
    eachHourOfInterval({
      start: dateRange.startDate.getTime(),
      end: dateRange.endDate.getTime(),
    })
      .filter(
        (date) =>
          date.getTime() > dateRange.startDate.getTime() &&
          date.getTime() < dateRange.endDate.getTime()
      )
      .forEach((date) => {
        thresholdData.push(createData(date))
      })
    thresholdData.unshift(createData(dateRange.startDate))
    thresholdData.push(createData(dateRange.endDate))
  }

  return {
    name: "Threshold",
    type: "line",
    color: mColors.systemError,
    lineWidth: 1,
    showInLegend: false,
    animation: true,
    connectNulls: false,
    tooltip: { format: "" },
    states: { hover: { enabled: false }, inactive: { enabled: false } },
    marker: { enabled: false },
    enableMouseTracking: false,
    dashStyle: "LongDash",
    data: thresholdData,
  }
}

export function getYPlotLinesDataSeries(
  yPlotLines: number[],
  dateRange: IGraphDateRange | undefined,
  data: TLineChartData[]
): Highcharts.SeriesOptionsType[] {
  const dataSeries: Highcharts.SeriesOptionsType[] = []
  yPlotLines.forEach((line, index) => {
    const yPlotLinesData = data.map((dataPoint) => {
      const xVal = new Date(dataPoint.dateTimeUtc)
      return [xVal.getTime(), line]
    })

    if (dateRange && data.length < 1) {
      yPlotLinesData.unshift([dateRange.startDate.getTime(), line])
      yPlotLinesData.push([dateRange.endDate.getTime(), line])
    }

    dataSeries.push({
      name: "yPlotline" + index,
      type: "line",
      color: mColors.systemError,
      lineWidth: 1,
      showInLegend: false,
      animation: false,
      connectNulls: false,
      tooltip: { format: "" },
      states: { hover: { enabled: false }, inactive: { enabled: false } },
      marker: { enabled: false },
      enableMouseTracking: false,
      dashStyle: "LongDash",
      data: yPlotLinesData,
    })
  })

  return dataSeries
}
